import React from 'react'
import './Playzoneau.css'; 
import ausflg from '../images/ausfl.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/ausfl.png'

function Hotel() {
  return (
    <div>
        <Helmet>
                <title>Best casino hotels in Australia</title>
                <meta name="description" content="Best casino hotels in Australia" />
                <link rel="icon" type="image/x-icon" href={favicon} />
            </Helmet>
         <nav class="navbar navbar-expand-lg sticky-top bg-white">
       <div class="container">
           <a class="navbar-brand" href="#"><img src={ausflg} class="top-logo" alt="logo.com"/> <span>Travel agency in Australia</span> </a>
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
               <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#home">Home</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#list">List</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#contact-us">Contact us</a>
                   </li>
               </ul>
           </div>
       </div>
   </nav>







   <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
       <div class="container">
           <div class="row justify-content-md-center">
               <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                   <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Visit Australia's best casino hotels</h3>
                   <p>Dreaming of a getaway that combines living room luxury with casino excitement? Look no further - Australia offers a selection of first class casino hotels that promise an unforgettable trip. Whether you're looking for an exclusive rooftop terrace or a luxury suite with breathtaking views, these carefully selected destinations offer the perfect combination of luxury, entertainment and the chance to try your luck at the gaming tables.</p>
               </div>
           </div>
       </div>
   </section>


   <section id="list" class="hotel section-padding padding-top-80">
       <div class="container">
           <div class="row">
               <div class="col-md-12">
                   <div class="section-howtouse text-left pb-5">
                       <div class="container-body">

                           <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back2} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Ville Resort & Casino</a></h5>
                                           <p class="card-text">
                                               For those who equally appreciate the tranquility and excitement of gaming, The Ville Resort & Casino offers the perfect balance. Relax in minimalist yet stylish rooms, clear your mind before diving into the world of gambling. From 9 to 5, experience the thrill of 370 slot machines and over 20 table games, providing endless entertainment during your stay. Yet, The Ville Resort is more than a gambler's paradise - it's an oasis of romantic serenity. Set in a Zen-like atmosphere, it is an idyllic place for an intimate retreat. Look forward to relaxing culinary experiences and enjoy the harmonious atmosphere that accompanies every moment. Consider The Ville Resort for weddings and romantic events, as it is a charming backdrop that creates precious memories.





                                           </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back4} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Pullman Reef Hotel Casino</a></h5>
                                           <p class="card-text">Experience pure sophistication at the Pullman Reef Hotel Casino. Imagine relaxing in a rooftop pool, surrounded by swaying palms, while enjoying the breathtaking view of the reef. The accommodations go beyond the ordinary, offering rooms with whirlpool baths and private balconies with panoramic views. Dive into a world of exquisite dining options, from handcrafted cocktails to fine dining experiences that create the perfect setting for your gaming experience. Enter the realm of the casino, where over 500 slot machines and a wide range of games like roulette, baccarat, and blackjack await. The excitement never stops, and you can immerse yourself in the world of gambling from 10 to 2 o'clock. Even if you have small children with you, no worries - a free babysitter is available.</p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back3} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Star Gold Coast</a></h5>
                                           <p class="card-text">
                                               Are you looking for a royal retreat? The Star Gold Coast is the answer. Enjoy luxurious suites that redefine opulence. Each room is a haven of refined comfort. Yet, the real allure awaits you outside your suite. Immerse yourself in a world of culinary delights at the eight restaurants and bars, and taste delicious creations that tantalize your taste buds. Relax at the Azure Spa and indulge in ultimate pampering before plunging into the exciting casino arena. With 70 table games and an astonishing 1400 slot machines, the thrill of the game is just a heartbeat away. Participate in ongoing tournaments or retreat to the exclusive Sovereign VIP gaming lounge to ensure every moment is unforgettable. The modern enthusiast will appreciate the variety of electronic slot machines and table games available around the clock.
                                           </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4 ">
                                   <div class="card list-hover">
                                       <img src={back5} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Crown Melbourne</a></h5>
                                           <p class="card-text">
                                               Crown Melbourne welcomes you with a variety of choices, whether you crave city views or river panoramas. Enjoy luxury in magnificent villas with expansive windows capturing breathtaking views. If you're seeking affordable luxury without compromising on style, you'll be thrilled by the thoughtfully designed rooms. Feast on a wide array of culinary delights, from diverse cuisines to specialty drinks, while the bar sets an atmosphere reminiscent of classic movies. Prepare to be impressed by the sheer size of the sprawling casino floor at Crown Melbourne, which spans over 220,000 square feet. Explore royal table games and hundreds of slot machines, or benefit from scheduled promotions that enhance your gaming experience. Even for curious onlookers, the establishment is a sight to behold, a journey through entertainment offerings that exceed the ordinary. Your extraordinary casino hotel vacation awaits - a fusion of luxury, excitement, and relaxation that will redefine your travel experience. Book your getaway today and experience the allure of Austria's finest casino hotels.
                                           </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>

               </div>
           </div>
       </div>
   </section>


   <section id="contact-us" class="hotel section-padding bg-light">
       <div class="container">
           <div class="row align-items-center">
               <div class="mapouter">
                   <div class="gmap_canvas">
                       <iframe width="100%" height="560" id="gmap_canvas" src="https://maps.google.com/maps?q=636+5th+Ave%2C+New+York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                   </div>
               </div>
           </div>
       </div>
   </section>





   <section class="why-us section-padding bg-white">
       <div class="container">
           <div class="row">

               <div class="row">
                   <div class="col-md-12">
                       <div class="section-howtouse text-left">
                           <div class="container-body Playfair-font">
                               <h1 class="Playfair-font section-title">More interesting places</h1>
                           </div>
                           <div class="container-body">

                               <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3">
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	The Ville Resort & Casino</a>
                                               </li>

                                               <li>
                                                   <a href="#" class="footer-link-tag">	Pullman Reef Hotel Casino</a>
                                               </li>

                                           </ul>
                                       </div>
                                   </div>
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	The Star Gold Coast</a>
                                               </li>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	Crown Melbourne</a>
                                               </li>


                                           </ul>
                                       </div>
                                   </div>
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>

                                               <li style={{color:"#717171"}}>
                                                   General inquiries: info@AuReefPlays.com

                                               </li>
                                               <li style={{color:"#717171"}}>
                                                   Travel inquiries: travel@AuReefPlays.com

                                               </li>
                                           </ul>
                                       </div>
                                   </div>


                               </div>
                           </div>
                       </div>

                   </div>
               </div>

           </div>
       </div>
   </section>

   <footer class="bg-light p-2 text-center">
       <div class="container padding-20">
           <div class="social-icon">
               <a href="#" class="fa fa-facebook"></a>
               <a href="#" class="fa fa-instagram"></a>
           </div>
           <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Privacy Policy</a>
           <p class="text-dark footer-text">© 2023 Copyright AuReefPlays
.com All rights reserved.</p>
       </div>
   </footer>
    </div>
  )
}

export default Hotel

